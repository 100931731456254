import React, { Fragment, useEffect, useState } from 'react' ; 
import { Col, Container, Navbar, Row } from 'react-bootstrap'; 
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";

const SideBaar = () => {
  const [games, setGames] = useState([]);
  const [mobile, setMobile] = useState(false);
  let width = window.innerWidth;
  useEffect(() => {
    const storedGames = localStorage.getItem("games");
    if (storedGames) {
      const gamesArray = storedGames.split(",");
      setGames(gamesArray);
    }
  },[])
  return (
    <>
      <Container>
      <Row>
        {
         (width < 500 && !mobile) && <span className="mobile-tab-btn" onClick={() => setMobile(!mobile)}><i className="bi bi-list-stars"></i></span>
        }
        <Col className={`fixed sidebar ${((width > 500 && mobile) ? "d-none" : '') || ((width < 500 && mobile) ? "d-block" : '')}`}> 
            <Navbar.Brand>
              <NavLink>
                <img className="w-100" alt="logo" src={logo} />
              </NavLink>
            </Navbar.Brand>
          <ul className="list-unstyled navbar-nav menu-bar dashboard">
            {width < 500 && <span className="moble-close-btn text-white" onClick={() => setMobile(!mobile)}><i className="bi bi-x-circle"></i></span>}
            {
              (localStorage.getItem("userRole") === "1") && <>
               <li className="nav-item">
                  <NavLink to="/subscripiton/payments">
                    <i className="bi bi-bookmark-plus"></i>
                    Subscription's
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/game-list">
                    <i className="bi bi-bookmark-plus"></i>
                    Game Manage
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment-option">
                    <i className="bi bi-bookmark-plus"></i>
                    Payment Gateway
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payout">
                    <i className="bi bi-bookmark-plus"></i>
                    Payout
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/sub-admin">
                    <i className="bi bi-bookmark-plus"></i>
                    Sub Admin
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/amount-list">
                    <i className="bi bi-bookmark-plus"></i>
                    Amount Option
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/user-payment">
                    <i className="bi bi-bookmark-plus"></i>
                    User Payment
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/withdrawal">
                    <i className="bi bi-bookmark-plus"></i>
                    Withdrawal
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/transactions">
                    <i className="bi bi-bookmark-plus"></i>
                    Transactions
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/locked-devices">
                    <i className="bi bi-bookmark-plus"></i>
                    Locked Devices
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink to="/tambola-future-games">
                    <i className="bi bi-bookmark-plus"></i>
                    Tambola
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/tambola-booking">
                    <i className="bi bi-bookmark-plus"></i>
                    Tambola Booking
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/numberprediction">
                    <i className="bi bi-bookmark-plus"></i>
                    Number Prediction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/game-history">
                    <i className="bi bi-bookmark-plus"></i>
                    NPD Game History
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/inactive">
                    <i className="bi bi-bookmark-plus"></i>
                    Inactive Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/gst">
                    <i className="bi bi-bookmark-plus"></i>
                    GST
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/tds">
                    <i className="bi bi-bookmark-plus"></i>
                    TDS
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/kyc">
                    <i className="bi bi-bookmark-plus"></i>
                    KYC
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/settings">
                    <i className="bi bi-bookmark-plus"></i>
                    Settings
                  </NavLink>
                </li>
              </>
            }
            {/* Default subadmin */}
            {
              ((localStorage.getItem("userRole") === "2") && (localStorage.getItem("userid") === "6132c0532cbb461d66a1f70f")) && <>
               <li className="nav-item">
                  <NavLink to="/dashboard">
                    <i className="bi bi-bookmark-plus"></i>
                    Dashboard
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-bookmark-plus"></i>
                    {localStorage.getItem("website")?.length > 0 ? "Agents" : "Users"}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/game-list">
                    <i className="bi bi-bookmark-plus"></i>
                    Game Manage
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment-option">
                    <i className="bi bi-bookmark-plus"></i>
                    Payment Gateway
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payout">
                    <i className="bi bi-bookmark-plus"></i>
                    Payout
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/sub-admin">
                    <i className="bi bi-bookmark-plus"></i>
                    Sub Admin
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink to="/amount-list">
                    <i className="bi bi-bookmark-plus"></i>
                    Amount Option
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/user-payment">
                    <i className="bi bi-bookmark-plus"></i>
                    User Payment
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/withdrawal">
                    <i className="bi bi-bookmark-plus"></i>
                    Withdrawal
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/tambola-future-games">
                    <i className="bi bi-bookmark-plus"></i>
                    Tambola
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/tambola-booking">
                    <i className="bi bi-bookmark-plus"></i>
                    Tambola Booking
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/numberprediction">
                    <i className="bi bi-bookmark-plus"></i>
                    Number Prediction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/game-history">
                    <i className="bi bi-bookmark-plus"></i>
                    NPD Game History
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/inactive">
                    <i className="bi bi-bookmark-plus"></i>
                    Inactive Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/gst">
                    <i className="bi bi-bookmark-plus"></i>
                    GST
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/tds">
                    <i className="bi bi-bookmark-plus"></i>
                    TDS
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/kyc">
                    <i className="bi bi-bookmark-plus"></i>
                    KYC
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/settings">
                    <i className="bi bi-bookmark-plus"></i>
                    Settings
                  </NavLink>
                </li>
              </>
            }
            {/* Subadmin Prmission */}
            {
              ((localStorage.getItem("userRole") === "2") && (localStorage.getItem("userid") !== "6132c0532cbb461d66a1f70f")) && <>
              {/* <li className="nav-item">
                  <NavLink to="/game-list">
                    <i className="bi bi-bookmark-plus"></i>
                    Game Manage
                  </NavLink>
                </li> */}
              {/* <li className="nav-item">
                  <NavLink to="/amount-list">
                    <i className="bi bi-bookmark-plus"></i>
                    Amount Option
                  </NavLink>
                </li> */}
              <li className="nav-item">
                <NavLink to="/users">
                  <i className="bi bi-bookmark-plus"></i>
                  {localStorage.getItem("website")?.length > 0 ? "Agents" : "Users"}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/user-payment">
                  <i className="bi bi-bookmark-plus"></i>
                  User Payment
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/withdrawal">
                  <i className="bi bi-bookmark-plus"></i>
                  Withdrawal
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tambola-future-games">
                  <i className="bi bi-bookmark-plus"></i>
                  Tambola
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tambola-booking">
                  <i className="bi bi-bookmark-plus"></i>
                  Tambola Booking
                </NavLink>
              </li>
              {!!games && games.map((data, index) => {
                  if (data === "651f9c4ea5e67037d308e120" && localStorage.getItem("appSlug")?.length > 0) {
                    return (
                     <Fragment key={index}>
                       {/* <li className="nav-item">
                        <NavLink to="/numberprediction">
                          <i className="bi bi-bookmark-plus"></i>
                          Number Prediction
                        </NavLink>
                      </li> */}
                      {/* <li className="nav-item">
                        <NavLink to="/game-history">
                          <i className="bi bi-bookmark-plus"></i>
                          NPD Game History
                        </NavLink>
                      </li> */}
                     </Fragment>
                    );
                  }
                })
              }
              <li className="nav-item">
                  <NavLink to="/settings">
                    <i className="bi bi-bookmark-plus"></i>
                    Settings
                  </NavLink>
                </li>
            </>
            }
            {/* agent */}
            {
              (localStorage.getItem("userRole") === "6") && <>
              <li className="nav-item" onClick={() => setMobile(!mobile)}>
                <NavLink to="/tambola-future-games">
                  <i className="bi bi-bookmark-plus"></i>
                  Tambola
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tambola-booking">
                  <i className="bi bi-bookmark-plus"></i>
                  Tambola Booking
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/game-tickets">
                  <i className="bi bi-bookmark-plus"></i>
                  Sell
                </NavLink>
              </li> */}
              </>
            }
            {/* supervisor */}
            {
              (localStorage.getItem("userRole") === "5") && <>
              <li className="nav-item">
                <NavLink to="/tambola-future-games">
                  <i className="bi bi-bookmark-plus"></i>
                  Tambola
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/withdrawal">
                  <i className="bi bi-bookmark-plus"></i>
                  Withdrawal
                </NavLink>
              </li>
              </>
            }
                <li className="nav-item">
                  <NavLink to="/profile">
                    <i className="bi bi-bookmark-plus"></i>
                    Profile
                  </NavLink>
                </li>
          </ul>
        </Col>
      </Row>
    </Container>

    </>
  )
}

export default SideBaar
